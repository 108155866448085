<template>
  <v-btn @click="search"> Page {{currentPage}} </v-btn>
</template>

<script>
import {mapActions, mapState} from 'vuex'

export default {
  name: 'LazyLoader',
  methods: {
    ...mapActions("conversations", ["search"]),
  },
  mounted() {
    this.search();
    // window.onscroll = () => {
    //   const el = document.documentElement
    //   const isBottomOfScreen =
    //       el.scrollTop + window.innerHeight === el.offsetHeight
    //
    //   if (isBottomOfScreen) {
    //     this.search();
    //   }
    // }
  },
  computed:{
    ...mapState("conversations", ["currentPage"]),
  },
  beforeDestroy() {
    window.onscroll = null
  },
}
</script>

<style scoped>

</style>
