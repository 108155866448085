<template>
  <v-container >
    <conversation-card v-for="conversation in conversations"
                       :key="conversation.id"
                       show-form="true"
                       :conversation="conversation"
                       class="elevation-4 ma-3 rounded conversation-row"
    >
    </conversation-card>
    <div class="text-center" v-if="loading">
      <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
      ></v-progress-circular>
    </div>
    <lazy-loader></lazy-loader>
  </v-container>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import ConversationCard from "@/components/conversations/ConversationCard.vue";
import LazyLoader from "@/components/conversations/LazyLoader";

export default {
  components: {
    ConversationCard,
    LazyLoader
  },
  data() {
    return {
      showConversationDialog: false,
      newLeadDialog: false,
      phone: '',
      email: '',
      contactName: '',
    };
  },
  computed: {

    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    ...mapGetters({
      conversations: "conversations/getSortedConversations",
    }),
    ...mapState("conversations", ["loading"]),
  },


  methods: {
    ...mapActions("conversations", ["newConversationAction"]),
  },
};
</script>

<style lang="scss" scoped>
  .conversation-row {
    background-color: gray;

    &:nth-of-type(2n) {
      background-color: lightslategray;
    }
  }
</style>
